.home-function-conatiner{
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    .title{
        padding-right: 100px;
        font-size: 35px;
        color: #1f4d9b;
    }
    .home-function-content{
        width: 100%;
        position: relative;
    height: 500px;
    min-width: 500px;
    background-image: url('./3.jpg');
    background-size:cover; /* Adjusts the size to cover the entire container */
    background-repeat: no-repeat; /* Prevents the GIF from repeating */
    background-position:center; 
    .mask{
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 440px;
        padding: 30px;
        opacity: .65;
    }
    .left{
        position: absolute;
        top: -50px;
        bottom: 50px;
        left: 20px;
        width: 230px;
        background-color: #a1c8ef;
        padding:20px;
        border-radius: 5px;
        .title{
            color: #fff;
            font-size: 18px;
            font-weight: 600;
        }
        .sub-title{
            color: #fff;
            font-size: 17px;
            font-weight: 600;
            margin-top: 5px;
        }
        .icon{
            height: 35px;
            margin-top: 10px;
        }
        .text{
            font-size: 13px;
            font-weight: 500;
        }
    }
    .content{
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 280px;
        padding:30px 50px;
        .sub-title{
            color: #000;
            font-weight: 600;
            font-size: 14px;
            margin-top: -10px;
        }
        .item-container{
            margin-top: 40px;
            .item{
                .item-label{
                    color: #36628e;
                    font-size: 15px;
                    display: flex;
                    align-items: center;
                    font-weight: 600;
                    .item-icon{
                        font-size: 30px;
                    }
                }
                .item-content{
                    margin-top: 12px;
                    color: #000;
                    .item-content-row{
                        font-size: 12px;
                        margin-bottom: 5px;
                        font-weight: 500;
                    }
                }
            }
        }
    }

}

    }


@media screen and (max-width:1000px) {
    .home-function-conatiner{
        
        .home-function-content{
            .left{
                display: none;
            }
            .content{
                left: 0;
            }
        }
    }
}
@media screen and (max-width:750px) {
    .home-function-conatiner{
        .title{
            font-size: 25px;
        }
        .home-function-content{
            .content{
                padding: 30px 20px;
                .item-container{
                    .item{
                        .item-label{
                            font-size: 15px;
    
                        }  
                        .item-content{
                            
                            .item-content-row{
                                font-size: 10px;
                            }
                        }
                    }
                }
            }
        }

    }
}