.home-part4-container{
    width: 100%;
    height: 500px;
    min-width: 500px;
    background-image: url('./4.jpg');
    background-size: cover; /* Adjusts the size to cover the entire container */
    background-repeat: no-repeat; /* Prevents the GIF from repeating */
    background-position:center; 
    position: relative;
    .mask{
        background: linear-gradient(to right, #ffffff, #a4afd1);
        
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 400px;
        opacity: .75;
        padding: 50px 0;

    }
    .home-part4-content{
        width: 60%;
        position: absolute;
        top: 30%;
        left: 5%;
        display: flex;
        flex-direction: column;
        align-items: start;
        .title{
            font-size: 20px;
            margin: 0;
            color:#1f4d9b;
            letter-spacing: 1px;
            word-spacing: 3px;
        }
        .btn{
            background-color:#c9b6d8;
            color:#fff;
            margin-top: 15px;
            border-radius: 20px;
            font-size: 12px;
            font-weight: 600;
            height: 35px;
            width: 200px;
        }

    }
    
}
@media screen and (max-width:1000px) {
    .home-screen-container{
        height: 400px;
        .mask{
            height: 300px;

        }

    }
}
