.home-part8-container{
    height: 300px;
    box-sizing: border-box;
    background-color: #fff;
    box-sizing: border-box;
    margin-bottom: 100px;
    padding:30px;
    .title{
        color:#000;
        font-weight: 700;
        font-size: 35px;
        margin: 10px 0 80px 0;
        
    }
    .content{
        display: flex;
        justify-content: space-around;
        align-items: center;

        .left{
            display: flex;
            .logo{
                height: 80px;
                margin-right: 20px;
                margin-bottom: 40px;
            }
            .detail{
                width: 250px;
                .company{
                    font-weight: 600;
                    font-size: 15px;
                    color:#1f4d9b;
                    margin-bottom: 10px;
                }
                .address{
                    font-size: 13px;
                    margin-bottom: 20px;
                }
                .desc{
                    font-size: 12px;
                }
            }
        }
        .right{
            display: flex;
            flex-direction: column;
            .media{
                display: flex;
                margin-bottom: 15px;
                .icon{
                    height: 30px;
                    margin-right: 10px;
                }
                .detail{
                    width: 250px;
                    .label{
                        font-weight: 600;
                        font-size: 15px;
                        color:#1f4d9b;
                        margin-bottom: 5px;
                    }
                    .value{
                        font-size: 13px;
                    }
                }
            }
        }
    }

    
}

@media screen and (max-width:750px) {
    .home-part8-container{
        .content{
            flex-direction: column;
            align-items: flex-start;
        }

    }
}
