.home-part5-container{
    padding:30px;
    background-color: #f5f5f5;
    .top{
        display: flex;
        align-items: center;
        .left{
            width: 200px;
            color:#1f4d9b;
            font-size: 30px;
            font-weight: 700;
            text-align: center;
            background-color: #eee;
            padding:20px 25px ;
            border-radius: 20px;
            margin-right: 30px;
        }
        .right{
            font-size: 13px;
            line-height: 20px;
        }
    }
    .content{
        margin-top: 20px;

        .title{
            font-size: 18x;
            font-weight: 700;
            text-align: center;
            margin-bottom: 30px;
        }
        .block{
            display: flex;
            justify-content: space-around;
            .item-one{
                background-image: url('./1.jpg');

            }
            .item-two{
                background-image: url('./2.jpg');
                
            }
            .item-three{
                background-image: url('./3.jpg');
                
            }
            .item{
                height: 150px;
                width: 200px;
                background-size: cover; /* Adjusts the size to cover the entire container */
                background-repeat: no-repeat; /* Prevents the GIF from repeating */
                background-position:center; 
                border-radius: 20px;
                overflow: hidden;
                position: relative;
                .mask{
                    background: linear-gradient(to right, #ffffff, #a4afd1);       
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    opacity: .75;
            
                }
                .text{
                    position: absolute;
                    top:40%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    width: 120px;
                    font-size: 15px;
                    font-weight: 600;
                    text-align: center;
                }
            }
        }
    }
    .arrow{
        height: 80px;
        margin-left: 50%;
        transform: translateX(-50%);
    }
    .foot{
        width: 320px;
        padding:0 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: rgb(208, 232, 252);
        border-radius: 10px;
        margin-left: 50%;
        transform: translateX(-50%);
        .logo{
            width: 100px;
            margin-right: 20px;
        }
        .content{
            .text{
                font-weight: 600;
                font-size: 12px;
                margin-bottom: 15px;
            }
        }

    }
}