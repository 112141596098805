.home-part6-container{
    position: relative;
    height: 500px;

    .background{
        width: 100%;
        height: 350px;
        background-image: url('./background.jpg');
        background-size: cover; /* Adjusts the size to cover the entire container */
        background-repeat: no-repeat; /* Prevents the GIF from repeating */
        background-position:center; 
        position: absolute;
        bottom: -30px;
        z-index: -1;
        .mask{
            background-color: #ffebca;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            height: 100%;
            opacity: .75;
    
        }
    }
    .title{
        text-align: center;
        color:#1f4d9b;
        font-size: 35px;
    }
    .content{
        margin-top: 20px;

        .title{
            font-size: 18x;
            font-weight: 700;
            text-align: center;
            margin-bottom: 30px;
        }
        .block{
            display: flex;
            justify-content: space-around;
            .item{
                height: 300px;
                width: 200px;

                border-radius: 20px;
                overflow: hidden;
                position: relative;
                .content{
                    position: absolute;
                    top:0;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 180px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    .img{
                        width: 100px;
                        margin-bottom: 10px;
                    }
                    .sub-title{
                        font-size: 13px;
                        font-weight: 600;
                        text-align: center;
                        margin-bottom: 15px;
                    }
                    .text{
                        
                        font-size: 11px;
                        text-align: center;
                    }
                }
                .mask{
                    background-color: #def4ff;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    opacity: .75;
            
                }

            }
        }
    }
    .arrow{
        height: 80px;
        margin-left: 50%;
        transform: translateX(-50%);
    }
    .foot{
        width: 300px;
        padding:0 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: rgb(208, 232, 252);
        border-radius: 10px;
        margin-left: 50%;
        transform: translateX(-50%);
        .logo{
            width: 100px;
        }
        .content{
            .text{
                font-weight: 600;
                font-size: 12px;
                margin-bottom: 15px;
            }
        }

    }
}