.home-window-container{
    padding: 30px 50px;
    display: flex;
    justify-content: space-around;
    .left{
        flex:1;
        margin-right: 30px;
        .title{
            font-size: 35px;
            color: rgb(56, 81, 162);
        }
        .desc{
            font-size: 12px;
        }
        .btn{
            background-color:#c9b6d8;
            margin-top: 15px;
            border-radius: 7px;
            font-size: 10px;
            font-weight: 600;
            height: 25px;
        }
        .block{
            display: flex;
            flex-wrap: wrap;
            margin-top: 20px;
            .item{
                flex:40%;
                margin-right: 20px;
                margin-top: 20px;
                .label{
                    display: flex;
                    align-items: center;
                    color:#5557ca;
                    font-family: Impact;
                    .icon{
                        border: 2px solid #5557ca;
                        color:#5557ca;
                        border-radius: 50%;
                        font-size: 11px;
                        padding:3px;
                        margin-right: 5px;
                    }
                }
                .desc{
                    margin-top: 5px;
                }
            }
        }
        
    }
    .right{
        padding-top: 80px;
        .image{
            width: 350px;
        }
    }
}

@media screen and (max-width:750px) {
    .home-window-container{
        flex-direction: column;

    }
}
