.home-part7-container{
    width: 100%;
    height: 500px;
    background-image: url('./background.png');
    background-size: cover; /* Adjusts the size to cover the entire container */
    background-repeat: no-repeat; /* Prevents the GIF from repeating */
    background-position:center; 
    position: relative;
    box-sizing: border-box;
    margin-top: 30px;
    .mask{
        background-color: #fff8f8;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 440px;
        padding: 30px;
        opacity: .65;
    }
    .content{
        margin-top: 20px;
        position: absolute;
        width: 80%;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        .title{
            text-align: center;
            color:#1f4d9b;
            font-size: 35px;
            font-weight: 600;
            margin-top: 20px;
        }
        .title-desc{
            font-size: 12px;
            text-align: center;
            margin-bottom: 30px;
        }
        .block{
            display: flex;
            justify-content: space-around;
            .item{
                height: 300px;
                width: 200px;
                border-radius: 20px;
                overflow: hidden;
                position: relative;
                margin-bottom: 20px;

                .content{
                    position: absolute;
                    top:0;
                    left: 50%;
                    transform: translateX(-50%);
                    height: 260px;
                    width: 180px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                    .img{
                        width: 100px;
                        margin-bottom: 10px;
                    }
                    .sub-title{
                        font-size: 13px;
                        font-weight: 600;
                        text-align: center;
                        margin-bottom: 15px;
                    }
                    .text{
                        font-size: 12px;
                        line-height: 20px;
                    }
                }
                .mask{
                    background-color: #def4ff;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    opacity: .75;
            
                }
                .btn{
                    background-color:#dbafe2;
                    color:#fff;
                    margin-top: 15px;
                    border-radius: 5px;
                    font-size: 10px;
                    font-weight: 600;
                    height: 30px;
                    width: 100px;
                }

            }
            .item-one{
                background-image: url('./1.jpg');
                background-size: cover; /* Adjusts the size to cover the entire container */
                background-repeat: no-repeat; /* Prevents the GIF from repeating */
                background-position:center; 
            }
            .item-two{
                background-image: url('./2.jpg');
                background-size: cover; /* Adjusts the size to cover the entire container */
                background-repeat: no-repeat; /* Prevents the GIF from repeating */
                background-position:center; 
            }
            .item-three{
                background-image: url('./3.jpg');
                background-size: cover; /* Adjusts the size to cover the entire container */
                background-repeat: no-repeat; /* Prevents the GIF from repeating */
                background-position:center; 
            }
        }
    }
    .arrow{
        height: 80px;
        margin-left: 50%;
        transform: translateX(-50%);
    }
    .foot{
        width: 300px;
        padding:0 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: rgb(208, 232, 252);
        border-radius: 10px;
        margin-left: 50%;
        transform: translateX(-50%);
        .logo{
            width: 100px;
        }
        .content{
            .text{
                font-weight: 600;
                font-size: 12px;
                margin-bottom: 15px;
            }
        }

    }
}
@media screen and (max-width:750px) {
    .home-part7-container{
        .content{
            .block{
                .item{
                    height: 350px;
                    width: 140px;
                    .content{
                        width: 120px;
                    }

               }
            }

        }

    }
}
